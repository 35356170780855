import { useEffect, useState } from 'react'
import './App.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import AnimatedRoutes from './utilities/AnimatedRoutes';
import FontFaceObserver from 'fontfaceobserver';
import FrontPage from './pages/FrontPage';
import About from './pages/About';
import CaseCoconessa from './pages/Cases/CaseCoconessa/CaseCoconessa';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './i18n';

function App() {
  const [isFontLoaded, setIsFontLoaded] = useState(false)
  useEffect(() => {
    const font_pitagno = new FontFaceObserver('Pitagon Sans Mono');
    const font_djr = new FontFaceObserver('Forma DJR Cyrilic Variable');

    Promise.all([font_pitagno.load(), font_djr.load()])
      .then(() => {
        setIsFontLoaded(true)
      })
      .catch((error) => {
        console.error('Ошибка загрузки шрифтов:', error);
        setTimeout(() => {
          setIsFontLoaded(true)
        }, 500);
      });
  }, []);

  return (
    <Router>
      <Header />
      <main>
        <AnimatedRoutes fontLoaded={isFontLoaded}>
          <>
            <Route path="/" element={<FrontPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/coconessa" element={<CaseCoconessa />} />
          </>
        </AnimatedRoutes>
      </main >
      <Footer />
    </Router>


  );
}

export default App;
