import { useEffect, useRef, useState } from 'react';
import CaseBtn from '../../Cases/CasesComponent/CaseBtn';
import './AboutReviews.scss'
import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';

function AboutReviews({ reviews }) {
   const [reviewsId, setReviewsId] = useState(0);
   const [reviewsIdChange, setReviewsIdChange] = useState(0);
   const reviewsRef = useRef([]);
   const { t, i18n } = useTranslation();

   const handleClickSetId = (e) => {
      const target = e.target
      const thistarget = target.closest('.about_reviews_list_content_bot-item')
      const thistargetId = thistarget.getAttribute('data-id')
      setReviewsIdChange(thistargetId)
   }

   const tl = gsap.timeline();

   useEffect(() => {
      if (reviewsRef && reviewsRef.current && reviews) {
         const reviews = reviewsRef.current
         const reviewsTopItem = reviews.querySelector('.about_reviews_list_content_top')
         const reviewsImages = reviews.querySelector('.about_reviews_list_images-image')

         tl.to([reviewsImages, reviewsTopItem], {
            opacity: 0,
            duration: .35,
            onComplete: () => {
               setReviewsId(reviewsIdChange)
            }
         })
         tl.to([reviewsImages, reviewsTopItem], {
            opacity: 1,
            duration: .35,
         })
      }

   }, [reviewsIdChange]);

   return (
      reviews && reviews.length > 0 &&
      <section className="about_reviews">
         <h2 className='section_title'>{t('aboutPage.Отзывы о нас')}</h2>
         <div className="about_reviews_list row" ref={reviewsRef}>
            <div className="col-xl-3">
               <div className="about_reviews_list_images">
                  <div className="about_reviews_list_images-image">
                     <img src={reviews[reviewsId].image}
                        alt={i18n.language === 'ru-RU' || i18n.language === 'ru' ? reviews[reviewsId].author : reviews[reviewsId].authorEn} />
                  </div>
               </div>
            </div>
            <div className="offset-xl-1 col-xl-4">
               <div className="about_reviews_list_content">
                  <div className="about_reviews_list_content_top">
                     <div className="about_reviews_list_content_top_item">
                        {(reviews[reviewsId].text || reviews[reviewsId].textEn) &&
                           <div className="about_reviews_list_content_top_item-text">
                              <p>{i18n.language === 'ru-RU' || i18n.language === 'ru' ? reviews[reviewsId].text : reviews[reviewsId].textEn}</p>
                           </div>
                        }
                        {(reviews[reviewsId].author || reviews[reviewsId].authorEn) &&
                           <div className="about_reviews_list_content_top_item-name">
                              {i18n.language === 'ru-RU' || i18n.language === 'ru' ? reviews[reviewsId].author : reviews[reviewsId].authorEn}
                           </div>
                        }
                        <CaseBtn link={reviews[reviewsId].caseLink} className={'about_reviews_list_content_top_item-btn'} />
                     </div>
                  </div>
                  <div className="about_reviews_list_content_bot">
                     {reviews.map((__, index) => (
                        <button type='button' className="about_reviews_list_content_bot-item" key={index} data-id={index} onClick={handleClickSetId}>
                           <div className="img">
                              <img src={reviews[index].image}
                                 alt={i18n.language === 'ru-RU' || i18n.language === 'ru' ? reviews[reviewsId].author : reviews[reviewsId].authorEn} />
                           </div>
                           <div className="glitch">
                              <img src={reviews[index].image}
                                 alt={i18n.language === 'ru-RU' || i18n.language === 'ru' ? reviews[reviewsId].author : reviews[reviewsId].authorEn} />
                           </div>
                        </button>
                     ))}
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}

export default AboutReviews;