import BreadCrumbs from "../components/utilities/BreadCrumbs/BreadCrumbs";
import "../components/AboutPage/About.scss";
import AboutBenefits from "../components/AboutPage/AboutBenefits/AboutBenefits";
import AboutTeam from "../components/AboutPage/AboutTeam/AboutTeam";
import AboutReviews from "../components/AboutPage/AboutReviews/AboutReviews";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

function About() {
   const [dataAbout, setDataAbout] = useState([])
   const { i18n } = useTranslation();

   // Получаем кейсы
   useEffect(() => {
      const fetchCases = async () => {
         try {
            const response = await fetch('/data/about.json');
            if (!response.ok) {
               throw new Error('Не удалось отправить запрос');
            }
            const data = await response.json();
            setDataAbout(data[0]);

         } catch (error) {
            console.error('Не удалось получить кейсы: ', error);
         }
      };

      fetchCases();
   }, []);


   return (
      <>
         <BreadCrumbs container={true} />
         <div className="about_page">
            <div className="container">
               <div className="about_page_wrap">
                  <h1 className="about_page_wrap-title page_title"
                     dangerouslySetInnerHTML={{ __html: i18n.language === 'ru-RU' || i18n.language === 'ru' ? dataAbout?.mainInfo?.title : dataAbout?.mainInfo?.titleEn }} />
                  <AboutBenefits className={'about_page_wrap'} dataBenefits={dataAbout?.aboutBenefits} />
                  <AboutTeam teamList={dataAbout?.team} />
                  <AboutReviews reviews={dataAbout?.reviews} />
               </div>
            </div>
         </div>
      </>
   );
}

export default About;