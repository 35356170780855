import { useTranslation } from 'react-i18next';

function AboutTeamItem({ dataItem, glitch }) {
   const { i18n } = useTranslation();
   return (
      <div
         className="about_team_item"
         style={{
            '--glitch': glitch, // Используем значение переменной
         }}>
         <div className="about_team_item-image">
            <div className="img">
               <img src={dataItem?.image} alt={dataItem?.name} />
            </div>
            <div className="glitch">
               <img src={dataItem?.image}
                  alt={i18n.language === 'ru-RU' || i18n.language === 'ru' ? dataItem?.name : dataItem?.nameEn} />
            </div>
         </div>
         <div className="about_team_item_content">
            <div className="about_team_item_content-name">
               {i18n.language === 'ru-RU' || i18n.language === 'ru' ? dataItem?.name : dataItem?.nameEn}
            </div>
            <div className="about_team_item_content-job">
               {i18n.language === 'ru-RU' || i18n.language === 'ru' ? dataItem?.job : dataItem?.jobEn}
            </div>
         </div>
      </div>
   );
}

export default AboutTeamItem;