import "./AboutBenefits.scss"
import AboutBenefitsItem from "./AboutBenefitsItem/AboutBenefitsItem";

function AboutBenefits({ dataBenefits }) {

   const dataList = [
      { text: 'Думаем, а&nbsp;не&nbsp;просто нажимаем на&nbsp;кнопки. Нам нравится создавать гибкие продукты c&nbsp;длинным жизненным циклом.' },
      { text: 'Применяем продуктовый подход. Пользовательские метрики&nbsp;&mdash; ROMI, DAU, LTV&nbsp;&mdash; наш главный триггер.' },
      { text: 'Умеем работать над продуктами на&nbsp;протяжении всего жизненного цикла: от&nbsp;нейминга и&nbsp;концепции до&nbsp;разработки сайта и&nbsp;стратегии продвижения.' }
   ]

   return (
      <div className="about_benefits row">
         {dataBenefits?.map((item, index) => (
            <AboutBenefitsItem
               dataItem={item} key={index}
               index={index + 1}
               col={`col-xl-2`}
            />
         ))}

      </div>
   );
}

export default AboutBenefits;