import { useEffect, useRef } from 'react';
import './AboutTeam.scss';
import AboutTeamItem from './AboutTeamItem/AboutTeamItem';
import { nanoid } from 'nanoid'
import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';

function AboutTeam({ teamList }) {
   const peopleListRef = useRef(null)
   const { t } = useTranslation();


   // const teamList = [
   //    {
   //       image: './img/about-team/irina-shatalyuk.jpg',
   //       name: 'Ирина Шаталюк',
   //       job: 'Проект менеджер'
   //    },
   //    {
   //       image: './img/about-team/ben-papyan.jpg',
   //       name: 'Бен Папян',
   //       job: 'Главный дизайнер, партнер'
   //    },
   //    {
   //       image: './img/about-team/mihail-rodionov.jpg',
   //       name: 'Михаил Родионов',
   //       job: 'Проект менеджер'
   //    },
   //    {
   //       image: './img/about-team/kristina-stakanova.jpg',
   //       name: 'Кристина Стаканова',
   //       job: 'Веб дизайнер'
   //    },
   //    {
   //       image: './img/about-team/dmitrij-suhov.jpg',
   //       name: 'Дмитрий Сухов',
   //       job: 'Веб дизайнер'
   //    },
   //    {
   //       image: './img/about-team/mihail-vrublevskij.jpg',
   //       name: 'Михаил Врублевский',
   //       job: 'Фронтенд разработчик'
   //    },
   //    {
   //       image: './img/about-team/aleksandr-kishchenko.jpg',
   //       name: 'Александр Кищенко',
   //       job: 'Веб разработчик'
   //    },
   //    {
   //       image: './img/about-team/nikita-goncharenkov.jpg',
   //       name: 'Никита Гончаренков',
   //       job: 'Фронтенд разработчик'
   //    },
   //    {
   //       image: './img/about-team/aleksandr-rodionov.jpg',
   //       name: 'Александр Родионов',
   //       job: 'Продюсер, партнер'
   //    },
   //    {
   //       image: './img/about-team/polina-matyusha.jpg',
   //       name: 'Полина Матюша',
   //       job: 'Графический дизайнер'
   //    },
   // ]

   const glitchVariables = [
      { glitch: 'glitch-anim-1' },
      { glitch: 'glitch-anim-2' },
      { glitch: 'glitch-anim-3' }
   ]

   const getRandomGlitch = () => {
      const randomIndex = Math.floor(Math.random() * glitchVariables?.length);
      return glitchVariables[randomIndex].glitch;
   };

   const getRandomPeople = () => {
      const randomIndex = Math.floor(Math.random() * teamList?.length);
      return [randomIndex];
   };
   useEffect(() => {
      const tl = gsap.timeline({

      })
      if (peopleListRef && peopleListRef.current && teamList) {
         const peopleList = peopleListRef.current.children;
         setInterval(() => {
            const glitch = getRandomGlitch();
            const randomPerson = getRandomPeople();
            const glitchimage = peopleList[randomPerson].querySelector('.glitch')

            tl.to(glitchimage, {
               animationName: glitch,
               animationDuration: '1.3s',
               animationIterationCount: '1',
               animationTimingFunction: 'linear',
               animationFillMode: 'forwards',
               duration: 6.3,
            })
            tl.to(glitchimage, { clearProps: 'all' });

         }, 6300);
      }
   }, [teamList]);

   return (
      <section className="about_team_block">
         <h2 className='section_title'>{t('aboutPage.Наша команда')}</h2>
         <div className="about_team row" ref={peopleListRef}>
            {teamList?.map(item => (
               <AboutTeamItem dataItem={item} key={nanoid()} glitch={getRandomGlitch()} />
            ))}
            <div className="about_team_item about_team_last">
               <div className="about_team_last-image">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                     <path d="M0 25.0005H50" stroke="#252525" strokeWidth="1.34217" />
                     <path d="M25 50L25 4.76837e-07" stroke="#252525" strokeWidth="1.34217" />
                  </svg>
               </div>
               <div className="about_team_item-title about_team_last-title">{t('aboutPage.Стать частью команды')}</div>
               <button type='button' aria-label={t('aboutPage.Стать частью команды')} className='about_team_last-btn'></button>
            </div>
         </div>
      </section>
   );
}

export default AboutTeam;