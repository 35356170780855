import { useTranslation } from 'react-i18next';

function AboutBenefitsItem({ dataItem, index, col }) {
   const { i18n } = useTranslation();
   return (
      <div className={col}>
         <div className={`about_benefits_item`}>
            <div className="about_benefits_item-num">
               {index}
            </div>
            <div className="about_benefits_item-text" >
               <p dangerouslySetInnerHTML={{ __html: i18n.language === 'ru-RU' || i18n.language === 'ru' ? dataItem?.text : dataItem.textEn }} />
            </div>
         </div>
      </div>
   );
}

export default AboutBenefitsItem;